import { Controller } from "@hotwired/stimulus"


export default class extends Controller {

    connect() {
        let tipTopic = this.element.querySelector('#tip_topic_id')
        let contentTip = this.element.querySelector('#content-tip-input')
        let submitButton = this.element.querySelector('#submit-tip-button')

        contentTip.addEventListener('input', () => {
            submitButton.disabled = contentTip.value.length < 8 || tipTopic.value == '';
        });
    }

}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if(window.innerWidth < 1024) {
      let firstSection = document.getElementById('about__first_section_copy');
      if (firstSection.clientHeight > 350) {
        firstSection.classList.add("facebook_messenger_hack");
      }
      let secondSection = document.getElementById('about__second_section_copy');
      if (secondSection.clientHeight > 370) {
        secondSection.classList.add("facebook_messenger_hack");
      }
    }
  }

  scrollToHowItWorks(event) {
    // Get the target div element by ID
    const targetDiv = document.getElementById('about__how_it_works');

    // Check if the targetDiv exists
    if (targetDiv) {
      // Scroll to the target div using smooth scrolling
      targetDiv.scrollIntoView({ behavior: 'smooth' });
      event.stopPropagation();
      console.log('stoped')
    }
  }

  scrollToChallenge() {
    // Get the target div element by ID
    const targetDiv = document.getElementById('about__section_challenge');

    // Check if the targetDiv exists
    if (targetDiv) {
      // Scroll to the target div using smooth scrolling

      targetDiv.scrollIntoView({ behavior: 'smooth' });
      console.log('started')
    }
  }
}

import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = ['commentsLink', 'commentsContainer'];

  toggleComments() {
    event.preventDefault();
    const commentsCollapseId = this.element.getAttribute('href');
    const commentsCollapse = document.querySelector(commentsCollapseId);
    if (commentsCollapse.classList.contains('show')) {
      commentsCollapse.classList.remove('show');
    } else {
      commentsCollapse.classList.add('show');
    }
  }
  
}

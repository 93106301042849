import * as bootstrap from 'bootstrap'
import * as Turbo from '@hotwired/turbo'

window.bootstrap = bootstrap;

Turbo.start()

// initialize the page
window.addEventListener('load', (event) => {
    initPage();
});

window.addEventListener('turbo:render', (event) => {
    initPage();
});

function initPage() {
    // initialize popovers
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl)
    })
}

import '../index.scss'
import '../controllers'

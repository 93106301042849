import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    let formElement = document.querySelector('#topic_search')
    let inputElement = formElement.querySelector('input.form-control')
    this.resultsContainer = document.querySelector('#search-results-container')
    let timeoutId = null

    formElement.addEventListener('submit', async (event) => {
        event.preventDefault();
        searchQuery(inputElement)
    });

    inputElement.addEventListener('input', async (event) => {
        if (timeoutId) {
            clearTimeout(timeoutId)
        }
        timeoutId = setTimeout(async () => {
            searchQuery(inputElement)
        }, 500)
    });
    document.addEventListener('click', handleClick.bind(this))
  }
}

function handleClick(event) {
    const clickedElement = event.target

    if (clickedElement === this.inputElement || this.element.contains(clickedElement)) {
      return
    }

    if (this.resultsContainer.contains(clickedElement)) {
      return
    }

    this.resultsContainer.classList.add('d-none')
}

async function searchQuery(inputElement) {
    const searchTerm = inputElement.value
    if (searchTerm != '') {
        const url = `/search.json?q%5Btitle_i_cont%5D=${searchTerm}`;
        const response = await fetch(url);
        const data = await response.json();
        displaySearchResults(data);
    } else {
        displaySearchResults([]);
    }
}

function displaySearchResults(results) {
    const resultsContainer = document.querySelector('#search-results-container')

    const searchResultsDiv = document.createElement('div');
    searchResultsDiv.classList.add('search-result-wrapper');

    const searchResultsTitle = document.createElement('p');
    searchResultsTitle.classList.add('search-result-title');
    searchResultsTitle.textContent = 'Tips';
    
    searchResultsDiv.appendChild(searchResultsTitle);

    results.forEach(result => {
      const resultDiv = document.createElement('div');
      resultDiv.classList.add('search-result');
      resultDiv.innerHTML = `
        <a href="/topic/${result.slug}">
            <p>${result.title}</p>
        </a>
        <span>
            <small>${result.tips_count}</small>
        </span>
      `;
      searchResultsDiv.appendChild(resultDiv);
    });
  
    resultsContainer.innerHTML = '';
    resultsContainer.appendChild(searchResultsDiv);
    resultsContainer.classList.remove('d-none')
}
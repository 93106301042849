import { Controller } from "@hotwired/stimulus"
import selectize from 'selectize';
import 'selectize/dist/css/selectize.css';
import $ from 'jquery';

export default class extends Controller {
    connect() {
        const oldSelectizeControl = $(this.element).siblings('.selectize-control');
        if (oldSelectizeControl.length) {
            $(oldSelectizeControl[0]).selectize()[0].selectize.destroy();
        }
        this.selectize = $(this.element).selectize({
            valueField: "id",
            labelField: "title",
            searchField: "title",
            create: false,
            load: this.loadOptions.bind(this),
            onChange: this.selectOption.bind(this)
        });
    }
  
    disconnect() {
      if (this.selectize) {
        this.selectize[0].selectize.destroy();
      }
    }
  
    loadOptions(query, callback) {
      fetch(`/search_for_select.json?q%5Btitle_i_cont%5D=${encodeURIComponent(query)}`)
        .then(response => response.json())
        .then(json => {
          callback(json)
        })
    }
  
    selectOption(value) {
      this.element.value = value
      let contentTip = document.querySelector('#content-tip-input')
      let submitButton = document.querySelector('#submit-tip-button')
      if (contentTip != null && submitButton != null) {
        submitButton.disabled = contentTip.value.length < 8 || this.element.value == '';
      }
    }
  }

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { redirectUrl: String }

  redirect() {
    let hotElementSelected = document.querySelector('.hot.active')
    if (hotElementSelected != null) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 50);
    }
  }
}

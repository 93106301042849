import { Controller } from "@hotwired/stimulus"


export default class extends Controller {

    connect() {
        this.element.querySelector('.comment-input').addEventListener('input', () => {
            this.element.querySelector('.comment-submit').disabled = this.element.querySelector('.comment-input').value.length < 8;
        });
    }

}

import { Controller } from "@hotwired/stimulus"


export default class extends Controller {

    connect() {
        let reportButtons = document.querySelectorAll(".report-button")
        reportButtons.forEach(function(button) {
            button.addEventListener("click", function() {
                button.style.color = "#FF373D"
                button.disabled = true;
                button.closest("form").submit()
            })
        })
    }

}
